import React from 'react';
import styles from './InvestorsPage.module.css';
import arrow from "../../assets/img/keyboard_double_arrow_right.svg";
import image from "../../assets/img/diagram.svg";
import {NavLink, useNavigate} from "react-router-dom";

const Investors = () => {
    const navigate = useNavigate();
    return (
        <div className={styles.container}>
            <div className={styles.background_stripes}>
                <div className={styles.stripe}></div>
                <div className={styles.stripe}></div>
                <div className={styles.stripe}></div>
                <div className={styles.stripe}></div>
            </div>
            <h1 className={styles.header}>Investors</h1>
            <p className={styles.text}>
                Now seeking strategic investment partners
            </p>
            <a href={'https://calendly.com/vrigenco/30min'} target="_blank" className={styles.button}>
                <p>Book a Meeting</p>
                <img src={arrow}/>
            </a>
            <p className={styles.text}>
                Send any questions or requests to: <a href="mailto:info@aiohub.gg"
                                                      className={styles.emailLink}>info@aiohub.gg</a>
            </p>
            <div className={styles.image_container}>
                <img src={image} alt="Diagram" className={styles.image}/>
            </div>
        </div>
    );
};

export default Investors;
